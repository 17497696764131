import 'bootstrap/dist/css/bootstrap.min.css';
import './Useless/ComplexTable'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ComplexTableExample from './Useless/ComplexTable';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavigationBar from './NavigationBar';
import Manage_buyer from './Manage Data/manage_buyer';
import Manage_team from './Manage Data/manage_team';
import Manage_party from './Manage Data/manage_party';
import ManageItem from './Manage Data/manage_item';
import ManageSpecification from './Manage Data/manage_specification';
import ManageUnits from './Manage Data/manage_units';
import ManageGrade from './Manage Data/manage_grade';
import Manage_gsm from './Manage Data/manage_gsm';
import Additional_work from './Manage Data/additional_work';
import ManageBrand from './Manage Data/manage_brand';
import ManageDepartment from './Manage Data/manage_department';
import ManagePartyBank from './Manage Data/manage_party_bank';
import ManageCompanyBank from './Manage Data/manage_company_bank';
// import ComplexTableExample from './Useless/ComplexTable';
import Add_new_order from './Functions/add_new_order';
import Add_new_party from './Manage Data/add_new_party';
import Demo_Table from './Useless/Demo_Table';
import ManageOrders from './Functions/manage_orders';
import PerfectTable from './Functions/perfect_table';
import ProductionPlanning from './Functions/production_planning';
import BoardMaking from './Functions/board_making';
import ManageFinishing from './Functions/manage_finishing';
import ManageDelivery from './Functions/manage_delivery';
import ManageDeliveryChallan from './Functions/delivery_challan';
import ManageGatePass from './Functions/manage_gate_pass';
import BMHistory from './Functions/BM_history';
import FinishingHistory from './Functions/finishing_history';
import NewDeliveryChallan from './Functions/new_delivery_challan';
import ChangeDeliveryChallan from './Functions/change_delivery_challan';
import NewGatePass from './Functions/new_gate_pass';
import CreateBill from './Bill/create_bill';
import BillList from './Bill/bill_list';
import ProformaInvoice from './Commercial/proforma_invoice';
import GroupPI from './Commercial/group_pi';
import CommercialTracking from './Commercial/commercial_tracking';
import NewGroupPi from './Commercial/new_group_pi';
import Commercial from './Commercial/commercial';
import EditGroupPi from './Commercial/edit_group_pi';
import DetailsReport from './Reports/details_report';
import LoginPage from './LoginPage';
import RequireAuth from './RequireAuth';
import ManageJobApproval from './Functions/manage_job_approval';
import ManageJobScheduling from './Functions/manage_job_scheduling';
import ChallanPDF from './PDFs/ChallanPDF';
import GatePassPDF from './PDFs/GatePassPDF';
import PiPDF from './PDFs/PiPDF';
import GroupPiPDF from './PDFs/GroupPiPDF';
import WorkOrderPDF from './PDFs/WorkOrderPDF';
import OrderSummary from './Reports/order_summary';
import DeliveryReport from './Reports/delivery_report';

// "homepage":"http://flowtech.aligroupbd.net/",
// window.backendUrl = 'http://localhost:3005'
window.backendUrl = 'https://flowtechserver.aligroupbd.net'

// git add .
// git commit -m "A commit"
// git push -u origin main

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<LoginPage/>} />
                <Route element={<RequireAuth />}>
                    <Route path="manage-orders" element={<ManageOrders/>} />
                    <Route path="/work-order-pdf-viewer/:orderId" element={<WorkOrderPDF />} />
                    <Route path="manage-job-approval" element={<ManageJobApproval/>} />
                    <Route path="manage-job-scheduling" element={<ManageJobScheduling/>} />
                    <Route path="production-planning" element={<ProductionPlanning/>} />
                    <Route path="board-making" element={<BoardMaking/>} />
                    <Route path='board-making-history' element={<BMHistory/>}/>
                    <Route path='manage-finishing' element={<ManageFinishing/>} />
                    <Route path='finishing-history' element={<FinishingHistory/>} />
                    <Route path='manage-delivery' element={<ManageDelivery/>} />
                    <Route path='manage-delivery-challan' element={<ManageDeliveryChallan/>} />
                    <Route path="/challan-pdf-viewer/:challanId" element={<ChallanPDF />} />
                    <Route path='new-delivery-challan' element={<NewDeliveryChallan/>} />
                    <Route path='change-delivery-challan' element={<ChangeDeliveryChallan/>} />
                    <Route path='manage-gate-pass' element={<ManageGatePass/>} />
                    <Route path="/gatePass-pdf-viewer/:gatePassId" element={<GatePassPDF />} />
                    <Route path='new-gate-pass' element={<NewGatePass/>} />
                    <Route path="create-bill" element={<CreateBill/>} />
                    <Route path="bill-list" element={<BillList/>} />
                    <Route path="proforma-invoice" element={<ProformaInvoice/>} />
                    <Route path="/pi-pdf-viewer/:piId" element={<PiPDF/>} />
                    <Route path="/group-pi-pdf-viewer/:groupPiId" element={<GroupPiPDF/>} />
                    <Route path="commercial-list" element={<Commercial/>} />
                    {/* <Route path="/commercial-tracking" element={<CommercialTracking/>} /> */}
                    <Route path="group-pi" element={<GroupPI/>} />
                    <Route path="new-group-pi" element={<NewGroupPi/>} />
                    <Route path="edit-group-pi" element={<EditGroupPi/>}  />
                    <Route path="add-new-order" element={<Add_new_order/>} />
                    <Route path="perfect-table" element={<PerfectTable />} />
                    <Route path="demo-table" element={<Demo_Table/>} />
                    <Route path="add-new-party" element={<Add_new_party />} />
                    <Route path="manage-party" element={<Manage_party />} />
                    <Route path="manage-buyer" element={<Manage_buyer />} />
                    <Route path="manage-team" element={<Manage_team />} />
                    <Route path="manage-item" element={<ManageItem />} />
                    <Route path="manage-specification" element={<ManageSpecification />} />
                    <Route path="manage-units" element={<ManageUnits />} />
                    <Route path="manage-grade" element={<ManageGrade />} />
                    <Route path="manage-gsm" element={<Manage_gsm/>} />
                    <Route path="additional-work" element={<Additional_work />} />
                    <Route path="manage-brand" element={<ManageBrand />} />
                    <Route path="manage-department" element={<ManageDepartment />} />
                    <Route path="manage-party-bank" element={<ManagePartyBank />} />
                    <Route path="manage-company-bank" element={<ManageCompanyBank />} />
                    <Route path="details-report" element={<DetailsReport />} />
                    <Route path="order-summary-report" element={<OrderSummary />} />
                    <Route path="delivery-report" element={<DeliveryReport />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;