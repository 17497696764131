import { useNavigate } from 'react-router-dom';
import { NavDropdown, Nav } from 'react-bootstrap';
import { Navbar } from 'react-bootstrap';
import { useContext } from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
// import useAuth from './hooks/useAuth';

function NavigationBar() {
  const navigate = useNavigate();
  // const title = 'MIAH & SONS INDUSTRIES LTD.';
  const [companyTitle, setCompanyTitle] = useState(localStorage.getItem('company'));
  
  useEffect(() => {
    const storedCompany = localStorage.getItem('company');
    if (storedCompany) {
      setCompanyTitle(storedCompany);
    }
  }, []);

  const handleLogOut = async () => {
    try {
      await axios.post(`${window.backendUrl}/logout`, {}, { withCredentials: true });
      localStorage.clear()
      navigate('/'); // Redirect to login or home page after logout
    } catch (error) {
      console.log('Error logging out:', error);
    }
  };

  const handleChangeCompany = () => {
    const currentCompany = localStorage.getItem('company');
    const newCompany = currentCompany === 'MIAH & SONS INDUSTRIES LTD.' ? 'Tanvir Printing & Packaging IND.' : 'MIAH & SONS INDUSTRIES LTD.';
    localStorage.setItem('company', newCompany);
    setCompanyTitle(newCompany); // Update state after setting localStorage
  };
  
  
  
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Navbar expand="lg" style={{backgroundColor:'rgba(135, 206, 235, 0.4)'}}>
      <Navbar.Brand style={{marginLeft:'20px'}}>
        <h3 style={{ color: 'rgba(0, 0, 0, 0.5)',fontFamily:'Apple-System',fontWeight:'900' }}>
          {companyTitle}
        </h3>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto" style={{fontSize:'15px'}}>
          <NavDropdown title="Functions" id="functions-dropdown">
            <NavDropdown.Item  onClick={() => handleNavigation('/manage-orders')}>
              Manage Orders
            </NavDropdown.Item>
            <NavDropdown.Item  onClick={() => handleNavigation('/manage-job-approval')}>
              Manage Job Approval
            </NavDropdown.Item>
            <NavDropdown.Item  onClick={() => handleNavigation('/manage-job-scheduling')}>
              Manage Job Scheduling
            </NavDropdown.Item>
            <NavDropdown.Item  onClick={() => handleNavigation('/production-planning')}>
              Production Planning
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/board-making')}>
              Board Making
            </NavDropdown.Item>
            <NavDropdown.Item  onClick={() => handleNavigation('/manage-finishing')}>
              Manage Finishing
            </NavDropdown.Item>
            <NavDropdown.Item  onClick={() => handleNavigation('/manage-delivery')}>
              Manage Delivery
            </NavDropdown.Item>
            <NavDropdown.Item   onClick={() => handleNavigation('/manage-delivery-challan')}>
              Manage Delivery Challan
            </NavDropdown.Item>
            <NavDropdown.Item  onClick={() => handleNavigation('/manage-gate-pass')}>
              Manage Gate Pass
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Stock" id="stock-dropdown">
            {/* <NavDropdown.Item onClick={() => handleNavigation('/perfect-table')}>
              Perfect Table
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/demo-table')}>
              Demo Table
            </NavDropdown.Item> */}
          </NavDropdown>
          <NavDropdown title="Bill" id="bill-dropdown">
            <NavDropdown.Item onClick={() => handleNavigation('/create-bill')}>
              Create Bill
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/bill-list')}>
              Bill List
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Commercial" id="commercial-dropdown">
            <NavDropdown.Item  onClick={() => handleNavigation('/proforma-invoice')}>
              Proforma Invoice
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/commercial-list')}>
              Commercial List
            </NavDropdown.Item>
            <NavDropdown.Item  onClick={() => handleNavigation('/group-pi')}>
              Group PI
            </NavDropdown.Item>
            <NavDropdown.Item   onClick={() => handleNavigation('/new-group-pi')}>
              New Group PI
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Manage Data" id="manage-data-dropdown">
            <NavDropdown.Item onClick={() => handleNavigation('/manage-party')}>
              Manage Party
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/manage-buyer')}>
              Manage Buyer
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/manage-team')}>
              Manage Team
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/manage-item')}>
              Manage Item
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/manage-specification')}>
              Manage Specification
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/manage-units')}>
              Manage Units
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/manage-grade')}>
              Manage Grade
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/manage-gsm')}>
              Manage GSM
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/additional-work')}>
              Additional Work
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/manage-brand')}>
              Manage Brand
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/manage-department')}>
              Manage Department
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/manage-party-bank')}>
              Com. Party Bank
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/manage-company-bank')}>
              Company Bank
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Reports" id="reports-dropdown">
            <NavDropdown.Item onClick={() => handleNavigation('/details-report')}>
              Details Report
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/order-summary-report')}>
              Order Summary
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavigation('/delivery-report')}>
              Delivery Report
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link onClick={handleChangeCompany}>Change Company</Nav.Link>
          <Nav.Link onClick={handleLogOut}>Log Out</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;

